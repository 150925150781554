import React from 'react';

export const appDevelopment = {
  id: 'app-development',
  title: 'App Development',
  keywords: 'app development, ios development, ios app, ios, android, android development, android app',
  shortDescription:
  <div>
    Mobile apps are constantly shaping how we live our lives since there is an app for everything nowadays. Here at
    MCRO, we acknowledged the value and benefits that apps bring and built a strong team of experts in this complex
    field who will meet all your needs and give you a product that will exceed your expectations, using innovation to
    develop and deliver the best mobile apps for iOS and Android.
  </div>,
  image: require('../../assets/images/services/pages/app-dev.jpg'),
  imageMobile: require('../../assets/images/services/pages/app-dev-mobile.jpg'),
  subtitle: 'What does MCRO offer in terms of App Development?',
  offerDescription: `With more than 10 years of thorough expertise in the App development field, MCRO’s software
    engineers focus on the technical as well as creative aspects to deliver high-quality services, on-time, with a
    maximized Return on Investment.`,
  servicesIncluded: ['UI/UX Design',
    'Compliance certification for app stores',
    'Native or cross-platform app development',
    'Testing of the mobile app',
    'Publishing and maintenance to app stores'
  ],
  categories: [
    {
      title: 'IOS development',
      description:
  <div>
    With over 500 million active devices worldwide, iOS has become one of the essential platforms for app
    developers and businesses. The community of iOS users is continuously growing, and that's why most business
    owners choose to have an app on the iOS platform. We assure you that you will reach out to a broader
    audience due to the marketing strategy implemented by our experts, and a futureproof product due to constant
    updates on the app when a new operating system is released.
  </div>
    }, {
      title: 'Android Development',
      description:
  <div>
    In 2022, the Google Play Store is expected to reach more than 130 billion downloads, so, you can reach customers on
    a global scale with android app development. Mobile applications provide plenty of advantages for companies,
    people, and society overall, ranging from productivity to advertising. The MCRO engineers’ team will develop your
    ideas to bring innovation to your business through the developed app. We will come to you with innovative
    methodologies, and we will have a brief case study to understand the target audience, the purpose of the app, and
    the best marketing strategy. This process requires performance maintenance, and once the app is available for the
    rest of the world, your business will have visible growth.
  </div>
    }, {
      title: 'Cross-platform',
      description:
  <div>
    MCRO is recognized for the innovative apps that brought a wider audience to our clients. Our engineers focus on
    cross-platform development using React Native or Flutter. It allows the users to access the app from various
    devices, such as mobile, desktop and web applications.
    <br />
    <br />
    The benefit of a cross-platform app is that it allows the developers to work with different devices and platforms.
    Therefore, productivity will be increased since it will save time and resources. A cross-platform app will increase
    the conversion rate and ease engagement with potential new customers. With the help of these apps, businesses can
    have their content on multiple devices without worrying about compatibility issues.
  </div>
    }, {
      title: 'React Native',
      description:
  <div>
    React Native is a cross-platform mobile app development framework built on JavaScript, designed to build native
    apps for iOS and Android. Successful digital products that adopted React Native into their stack are Airbnb,
    Netflix and Instagram. The main advantage of React Native is that it offers an easy method to create
    cross-platform apps that can be used in web and native environments. Therefore, the developers don't have to write
    two different codes for their app or learn two other languages at once. This will save time and energy, and the
    process of developing apps will be faster, more reliable and easier to maintain. The clients will reach a higher
    audience due to targeting multiple platforms with the same codebase.
  </div>
    }, {
      title: 'Flutter',
      description:
  <div>
    Flutter is a cross-platform mobile application development framework that makes it easy to create high-quality
    native apps for iOS and Android. It uses Dart as a programming language It offers more than 3200 widgets that
    can be used to build apps of any size with ease.
    <br />
    <br />
    With Flutter, you can have a more comprehensive and diverse community due to the support of 24 languages.
    Furthermore, Flutter makes the app fully accessible for everyone thanks to some features: large fonts,
    increased contrast and screen readers. Our engineers will put together the client's business objective and
    the technological solutions in an innovative way to achieve the best results
    <br />
    <br />
    If you are looking for mobile app development, MCRO is the choice. You can have an Android app or an iOS app,
    depending on your needs, or why not on both platforms. If you want to know more about the iOS and Android market
    insights, feel free to read our blog about “
    <a href="https://mcro.tech/blog/app-revenue/" style={{ color: '#0363c1' }}>How much money can an app make you</a>
    ”. The engineer's team is open to any challenge and will work together for higher efficiency and for giving you the
    best digital product for your needs.
  </div>
    },
  ]
};
