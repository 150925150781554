import React from 'react';
import { appDevelopment } from './services/app-development';
import { webDevelopment } from './services/web-development';
import { cloudInfrastructure } from './services/cloud-infrastructer';
import { ideationAndProductStrategy } from './services/ideation-and-product-strategy';
import { uiUxDesign } from './services/ui-ux-design';

export const Services = [
  appDevelopment,
  webDevelopment,
  cloudInfrastructure,
  ideationAndProductStrategy,
  uiUxDesign
];
