import React from 'react';

export const webDevelopment = {
  id: 'web-development',
  title: 'Web Development',
  keywords: 'web development, application, react, website, back-end, front-end',
  shortDescription:
  <div>
    Web development services are used to design, build, and support your digital product ideas to evolve your business
    and deliver tangible results. With more than 10 years of experience and a lot of passion about technology,
    <a href="https://mcro.tech" style={{ color: '#0363c1' }}> MCRO's </a>
    team of top-tier architects and full-stack engineers help leverage the ever-changing digital landscape, by using
    cutting-edge technologies and techniques, creating contemporary, scalable web apps adapted to your demands
  </div>,
  image: require('../../assets/images/services/pages/web-dev.jpg'),
  imageMobile: require('../../assets/images/services/pages/web-dev-mobile.jpg'),
  subtitle: 'What does MCRO offer in terms of web development?',
  offerDescription: `Our website developers provide expert web application development and web design services for a
  variety of industries.`,
  servicesIncluded: ['Increased customer reach and conversions',
    'Develop/expand digital business channels',
    'Growth of customer base',
    'Ability to track and analyse customer data',
    'Increase brand recognition and market position'
  ],
  categories: [
    {
      title: 'Front-end Development',
      description:
  <div>
    Front-end developers are responsible for crafting the user experience, which includes ease of navigation through
    the site and the aesthetic appeal that the site has. They're also responsible for developing interactive elements
    like forms, buttons, and menus. By using HTML, CSS, JavaScript, and powerful frameworks like React, Vue or Angular
    with the right setup for architecture and state management  our engineers ensure a high-quality custom output.

  </div>
    }, {
      title: 'Back-end Development',
      description:
  <div>
    While a front-end developer creates the user interface, back-end developers focus on the logic behind it. Behind
    any successful project there is a team of back-end engineers who ensure that the system is secure and scalable.
    A back-end engineer is responsible for creating and maintaining the server-side of an application. We are experts
    in building and managing databases to help you choose which database to use and how it should be structured, that
    will guarantee the speed and  the scalability of your web application.
    <br />
    <br />
    Technologies that we recommend on backend are NodeJS (using NestJS) on server and serverless architectures, but
    depending on your project requirements other technologies like GO, Java or Python could be a great choice.
  </div>
    }, {
      title: 'Systems Architecture',
      description:
  <div>
    Data architecture is designing the data elements that are needed to support an application. It can be a tedious
    task for developers and often gets overlooked.
    <br />
    <br />
    A well-structured data architecture includes both the physical and logical design of data elements. The physical
    design defines how data will be stored on disks or other storage media, while the logical structure defines how
    users will access this information. It also includes consideration for how changes in one area will affect other
    system areas. Our team of engineers will consider every aspect of the system architecture and give the best
    version for the clients.
  </div>
    }, {
      title: 'Websites',
      description:
  <div>
    An expert website developer or designer can help you with all aspects of your website. The engineers from
    <a href="https://mcro.tech" style={{ color: '#0363c1' }}> MCRO Tech </a>
    are experienced in creating websites that are optimized for search engine rankings, increase conversions,
    and have a user-friendly interface. Our expert website developer or designer can help you with all aspects of your
    website. Frameworks like NextJS and Gatsby that are built on the top of React, offer you the experience and
    performance of a single page application with support for SEO, progressive image loading, markdown content
    creation, page speed optimizations and performant build system.
  </div>
    }, {
      title: 'Web Apps & Platforms',
      description:
  <div>
    Web apps are different from websites in many ways. Web apps are designed to be used on a specific device and
    have a more native feel. They also have a more interactive design, and can use features that websites cannot,
    such as push notifications, background sync, and offline support. More than ever before, companies should be
    investing in web apps or platforms that can help them automate their marketing strategies so they can focus on
    what's important - their products and services!
  </div>
    }, {
      title: 'E-commerce',
      description:
  <div>
    We help you build unique e-commerce websites that will attract many potential customers. The online retail industry
    is growing every day, and it is on the verge of taking over the traditional retail market. Online retailers have
    become more popular and can now compete with their physical counterparts in terms of sales and customer service.
    To stay competitive, companies need to keep up with technological advancements in the industry, which includes
    building websites that can be easily navigated by customers and can also be updated on the fly.
  </div>
    },
  ]
};
