import React from 'react';

export const ideationAndProductStrategy = {
  id: 'ideation-and-product-strategy',
  title: 'Ideation and product strategy',
  keywords: 'Ideation and product strategy, design sprint, user experience',
  shortDescription:
  <div>
    Product strategy is at the heart of all successful digital products. We work with our clients to turn all their
    great ideas into a goal-oriented, focused product strategy that we can successfully implement together. Our
    dedicated team helps you understand your users, align their needs with your organization's goals, and design
    strategies for success.
  </div>,
  image: require('../../assets/images/services/pages/ideation-product-strategy.jpg'),
  imageMobile: require('../../assets/images/services/pages/ideation-product-strategy-mobile.jpg'),
  subtitle: 'What does MCRO offer in terms of ideation and product strategy?',
  offerDescription: `Building a product is more than just designing. This process considers the competitive and
  relative position of the product, the business model, the messaging, and the marketing used to plan what to build
  and when to build it. Product ideation is essentially a strategic session that is part of the development process,
  bringing your entire team together and leveraging the insights, experiences and ideas of product owners, designers,
  developers, QAs, and architects. At MCRO, we are dedicated to transforming and developing your business to reach
  new benchmarks and develop a product that exceeds all your boundaries. With over a decade of experience in the
  industry, we help you develop a strong, futureproof product from scratch, maximizing value and effectiveness
  throughout its lifecycle.`,
  servicesIncluded: [
    'Turn ideas into reality',
    'Prototype ideas',
    'Validate ideas with a real sample of users',
    'Translate inefficient processes into efficient ones',
    'Have a sharp product roadmap',
    'Have a strong value proposition',
    'Understand your users and their needs',
    'Understand trends, competitors and disruption threads and opportunities',
    'Iterate and improve regularly',
    'Use data to motivate customer engagement'
  ],
  categories: [
    {
      title: 'Discovery Workshops',
      description:
  <div>
    Discovery workshops are the stepping-stones that lead to a better understanding of the developing product. These
    workshops focus on team and stakeholder collaboration to gain as much information as possible about the product.
    It gives a birds-eye-view of the entirety of the project from business objectives and goals to design, user
    experience, and technical aspects.
    <br />
    <br />
    Workshops make it easy to simplify complex projects, providing a structured roadmap and clear requirements that are
    in line with your goals. Emphasising project areas that lack clarity helps identify potential issues that may arise
    later and identify discrepancies. Also, at this stage, product owners and managers must understand customers' and
    stakeholders’ expectations, define what sets the product apart in the market, and build a product mission, vision,
    and overall direction.
    <br />
    <br />
    No matter how great your idea may seem to be at the beginning, if not enough thought is put into it at the
    inception stages of development, your product can lose its value. The MCRO team has very broad expertise in
    managing, designing, and building your ideas and turning them into reality.
  </div>
    }, {
      title: 'Design Sprints',
      description:
  <div>
    The Design Sprint is a rapid prototyping technique that allows real users to answer difficult business questions,
    develop new products, and improve existing ones in a very short time. Design Sprints are built on a methodology
    originally introduced by Google Ventures and are a proven process for validating a product's vision before
    investing development resources. Instead of waiting to bring a minimal product to market to understand if an idea
    is good, get strong data and consumer feedback from a representative prototype. This prototyping methodology is
    followed by 5 steps:
    <br />
    <br />
    &nbsp; 1. Outline the problem
    <br />
    &nbsp; 2. Ideate and benchmark
    <br />
    &nbsp; 3. Prototype
    <br />
    &nbsp; 4. Test with consumers
    <br />
    &nbsp; 5. Learn and iterate
    <br />
    <br />
    The Design Sprint process creates a lightweight, interactive prototype that you can use to gather insights from
    your target audience. MCRO uses this to link to the actual goals and key outcomes you want to achieve. It's a way
    to design and fine-tune the optimal solution that meets both your business goals and your needs. By using this, we
    will help users accelerate product adoption since they are involved in the process from the beginning.
  </div>
    }, {
      title: 'Innovation Consulting',
      description:
  <div>
    The development and use of new products and technologies is an important factor in securing and strengthening a
    products’ market position. Innovation and systematically planned processes enable companies to differentiate
    themselves from their competitors and drive growth and efficiency. The necessary strategic further development of
    the business area is based on identifying and assessing market potential, assessing analytical techniques, and
    considering external scenarios.
    <br />
    <br />
    Our team of experts is proficient in leveraging the power of technology to ensure your business is ready for the
    future and ahead of the competition. We provide a fresh perspective and focus on innovation, scalability and
    exploring new ways of doing business, and use best practices in the field to create an industry-leading product.
    <br />
    <br />
    A dedicated team of specialists support you at every stage to provide end-to-end, best-in-class IT integration and
    consulting services. From strategic discovery and identification of growth opportunities to implementation,
    support, and optimization, we are committed to your long-term success.
  </div>
    }, {
      title: 'UX Audit',
      description:
  <div>
    In today's ever-changing technology industry, maintaining complete design consistency is a challenge, especially
    when multiple teams are working on the same digital product. UX Audit is a quality assurance process that evaluates
    existing digital products to ensure they meet business, user experience, and accessibility requirements. This
    allows UX designers to use valuable real-world data to identify user issues and business opportunities. Testing
    during the design process is a limited representation of how a product works in the real world.
    <br />
    <br />
    Our team of UX designers conduct regular audits allowing us to evaluate products and identify issues related to
    continuity, consistency, accessibility, and usability. We will show you what to keep, what to avoid, and will
    establish the next steps together. MCRO strongly believes that an enhanced user experience directly impacts how
    your customers perceive your product or software solution.
  </div>
    }, {
      title: 'Competitive Analysis',
      description:
  <div>
    Competitive analysis is typically performed to identify the strengths and weaknesses of competitors in the same
    market segment, define the company's position, identify gaps, and assess your potential to fill them. When your
    stakes are as high as your business, you need to know what you are up against.
    <br />
    <br />
    Brand positioning requires finding out where you are in the market and comparing it to your goals and strategies.
    Your brand position clearly shows how your company is perceived and whether your message was heard or received in
    an intended way.
    <br />
    <br />
    The MCRO team of marketing experts always have their hand on the market pulse and is always up to date with the
    newest trends and best practices. We will help you:
    <br />
    <ul>
      <li>Review your internal statistics</li>
      <li>Catch up with market trends</li>
      <li>Update your marketing mix</li>
      <li>Provide content ideas, keyword data, and SEO activity</li>
      <li>Refine your goals</li>
    </ul>
  </div>
    }, {
      title: 'Product Roadmap',
      description:
  <div>
    There are no two projects in the tech industry that are the same. That’s why the role of project owners and
    managers is to prioritise things based on the expected benefits, to arrive at the best possible output. The product
    roadmap is a high-level summary that shows the vision and direction of product delivery in chronological order.
    It tells you why and what's behind what you're building. The roadmap is a strategic guide document and a plan for
    implementing a product strategy.
    <br />
    <br />
    Here at
    <a href="https://mcro.tech" style={{ color: '#0363c1' }}> MCRO</a>,
    our project managers paint a clear, well-structured picture of your ideas and allocate resources in
    a very feasible way for our clients to receive the highest return on investment. By developing product roadmaps,
    we assure:
    <br />
    <ul>
      <li>Assess the existing conditions and how that match with the clients’ desired outcome</li>
      <li>The vision and strategy development</li>
      <li>Provide regulatory documentation for executing the strategy</li>
      <li>Get core stakeholders in alignment</li>
      <li>Facilitate discussion of routes and scenario planning</li>
      <li>Help link with external stakeholders, including customers</li>
      <li>Schedule periodic reviews of your roadmap to account for any changes to strategy or business conditions</li>
    </ul>
  </div>
    },
  ]
};
