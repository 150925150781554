import React from 'react';
import PropTypes from 'prop-types';
import ServicePageDetails from '../components/ServicePageDetails';
import { Services } from '../data/services-data';

const ServicesPage = ({ location }) => {
  const service = Services.find(item => `/services/${item.id}/` === location.pathname);

  return (
    <ServicePageDetails
      location={location}
      service={service}
    />
  );
};


ServicesPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
};

ServicesPage.defaultProps = {
  data: null
};

export default ServicesPage;
