import React from 'react';

export const uiUxDesign = {
  id: 'ux-ui-design',
  title: 'UX/UI Design',
  keywords: 'ui, ux, ui ux design, design',
  shortDescription:
  <div>
    Providing a complete digital experience is like a hub concentrating together all the key points, connecting
    devices, and creating complex experiences. The UX/UI Design process aims to identify the most-wanted features
    and prioritise them according to their importance. MCRO`s strategy and UX team can create solutions that are
    both intuitive to use and tailored to match our clients’ specific user tasks and business goals, using industry
    leading best practices and techniques and latest technology tools.
  </div>,
  image: require('../../assets/images/services/pages/ux-design.jpg'),
  imageMobile: require('../../assets/images/services/pages/ux-design-mobile.jpg'),
  subtitle: 'What does MCRO offer in terms of UX/UI?',
  offerDescription: `Our “tech artists” design and create digital experiences that are persistent and pleasant for our
  clients and most importantly, for end-users. At MCRO, we focus on what’s really important for you, and we will make
  sure that all demanded and essential features are provided at the highest level.`,
  servicesIncluded: [
    'UX Design',
    'User Research & Analysis',
    'Customer Journey Mapping',
    'UI and Interaction Design',
    'User Testing',
    'Customer Experience Consulting',
    'Design Thinking',
  ],
  categories: [
    {
      title: 'Discovery & Strategy',
      description:
  <div>
    These days, new businesses and products appear every day. The tricky part is to make them visible to various
    potential customers, so it would be best if you had a well-defined marketing strategy to overcome the
    possible problems that may occur.
    <br />
    <br />
    The discovery process is vital in creating a digital product. It requires a lot of documentation since before
    developing an app, the team needs to have a well-organized system about what the business wants. The design team
    needs to come up with diversified and elaborate plan to increase productivity, boosts profits, and enhance the
    public image.
    <br />
    <br />
    The strategy is critical because it helps the team identify a company's objectives, determine the best means to
    achieve those objectives, and implement a plan to achieve those objectives. Our specialized team will continuously
    develop research and shape the product and its features. We will help you implement new strategies for your
    business, optimize processes, and present the market with a new and innovative digital product.
  </div>
    }, {
      title: 'User Experience Design',
      description:
  <div>
    A user experience design or UX design enhances interaction with a product through a well-defined combination of
    research, analysis, design, and implementation. Our UX designers are experts in delivering the best services and
    efficiently research market preferences and adapting to them.
    <br />
    <br />
    The main goal of a UX designer is to develop environments that are insightful for users. They need to come up with
    innovative strategies to attract a broader audience and design an appealing and easy to use product.
    <br />
    <br />
    User experience is a vital part of conversion optimization. With the appropriate strategy, your business can have
    a substantially increased conversion rate. Some methods to boost your conversions are:
    <br />
    <ul>
      <li>designing a user-friendly interface </li>
      <li>optimizing landing pages with A/B testing</li>
      <li>using content marketing to increase conversions</li>
      <li>creating a strong call to action</li>
      <li>making sure users can find what they need</li>
    </ul>
  </div>
    }, {
      title: 'User Interface Design',
      description:
  <div>
    The first impression a user has of your website or app is determined by the user interface design. That first
    contact can be crucial in determining the product’s likeability. Usability becomes ever more important as the
    user becomes more involved. Both of these aspects impact the UI design experience and help to convey your brand
    and product's narrative. MCRO creates user experiences that turn your digital solution into a product that people
    want to use. Our goal is to make the lives of your users as easy and enjoyable as possible.
    <br />
    <br />
    Our UI team has the expertise in generating visually appealing and functional interfaces for your digital product
    ideas. From corporate and campaign websites to applications, e-commerce, dashboard visualisations, and complicated
    business solutions, we've done it all. We believe that in order to produce a seamless and consistent user
    experience, creativity and aesthetic design are just as crucial as the technical side of the product. We provide
    simple, effective, intuitive, and pleasurable interface design solutions that fit the product and brand purpose
    and meet your users' demands by combining insights, a strategic approach branding, and UX best practices.
  </div>
    }, {
      title: 'Design Systems',
      description:
  <div>
    A design system is a digital marketing framework applicable to any digital product, including websites, apps, and
    user interfaces. It's a methodical technique that aids the organization in creating and maintaining consistent
    branding across all its goods. Design systems are gaining popularity because they enable businesses to create items
    faster and with less effort than before. The design systems also include the brand's visual assets, fonts, logo,
    and colours.
    <br />
    <br />
    The codebase and the documentation are the two pieces of a design system. The codebase is a collection of CSS and
    HTML files that define the appearance of all pages in your product. The MCRO team will assure the development of
    your business in a short time and also assist businesses in coming up with fresh ideas by offering a defined method
    for developing new designs or upgrading old ones.
  </div>
    }, {
      title: 'Prototyping',
      description:
  <div>
    The prototyping process is vital in any domain. A prototype is a fully functional representation of something and
    is usually built using software tools. It has detailed information and is used to test out the reaction from
    potential customers. The primary purpose of this process is to receive feedback from users about a product or
    service and what adjustments could be made.
    <br />
    <br />
    In software development, we use prototypes to test out our ideas and ensure they are going in the right direction.
    This step will increase the efficiency of delivering the best digital product. A prototype will allow the
    developers to test their software, see any problems before launching it, and also present it to the client for
    potential final touches. Therefore, the risk is mitigated, and the team knows how to improve the product before
    it goes live.
  </div>
    }, {
      title: 'Interaction Design',
      description:
  <div>
    The technique of creating interactive goods is known as interaction design. It entails the creation of interactive
    systems as well as human-technology interaction. Interaction designers are in charge of ensuring that people can
    readily engage with their product or service and use it in the most efficient manner possible.
    <br />
    <br />
    One of the fundamental goals of interface design is to provide an enjoyable experience for users. This is achieved
    by considering what people need and desire from their product, ensuring that people understand how to use the
    product and making an intuitive usage flow.
    <br />
    <br />
    The
    <a href="https://mcro.tech" style={{ color: '#0363c1' }}> MCRO </a>
    team has the necessary experience to deliver interactive products that will boost your business. Give us
    the chance to show that your business can grow and develop harmoniously using the right skills.
  </div>
    },
  ]
};
