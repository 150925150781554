import React from 'react';

export const cloudInfrastructure = {
  id: 'cloud-infrastructure',
  title: 'Cloud Infrastructure',
  keywords: 'cloud infrastructure, security, migration services, cloud integration',
  shortDescription:
  <div>
    Our dedicated team of DevOps and infrastructure architects can help you optimise and scale your business at any
    stage of your cloud journey. It also provides increased security by keeping data in an isolated environment
    from other users. From cloud assessment and strategy to infrastructure setup, migration, and maintenance, we’ve
    got your back.
  </div>,
  image: require('../../assets/images/services/pages/cloud-infrastructure.jpg'),
  imageMobile: require('../../assets/images/services/pages/cloud-infrastructure-mobile.jpg'),
  subtitle: 'What does MCRO offer in terms of Cloud Infrastructure?',
  offerDescription: `Our team, having extensive expertise, assures cloud assessment and strategy, infrastructure setup,
  migration, and maintenance. The engineers will make sure everything goes according to the plan, and the client will
  have the certainty that our DevOps will come up with the most reliable solutions.`,
  servicesIncluded: [
    'Cost Savings',
    'Security',
    'Flexibility',
    'Increased Collaboration',
    'Quality Control',
    'Loss Prevention',
    'Automatic Software Updates',
    'Sustainability',
    'Easy Scale',
    'Serverless Infrastructures',
    'Monitoring Tools'
  ],
  categories: [
    {
      title: 'Continuous Integration',
      description:
  <div>
    Continuous integration aims to discover and repair integration issues as soon as possible, eliminating the need
    for manual testing and debugging. By using a Continuous Integration tool, developers can speed up the software
    development process in a cost-effective way. This methodology has various advantages, including code quality,
    increased productivity, reduced time to market, and reduced defect rates.
    <br />
    <br />
    Continuous integration is necessary for any website, and our DevOps are highly experienced in this domain. We use
    this software development service to incorporate code that is changed regularly. It is a Cloud-Based Infrastructure
    as a Service (IaaS) that enables organisations to create and manage their private cloud infrastructure.
  </div>
    }, {
      title: 'Infrastructure Management',
      description:
  <div>
    Infrastructure management is another service provided by our DevOps team. It deals with the process of managing and
    maintaining the IT infrastructure. This operation is essential for:
    <br />
    <ul>
      <li>Managing servers and virtual machines in a data centre</li>
      <li>Deploying new software or hardware on existing systems</li>
      <li>Monitoring the health of servers and applications for performance optimization</li>
      <li>Maintaining security across all systems</li>
    </ul>
  </div>
    }, {
      title: 'Cloud Migration Services',
      description:
  <div>
    MCRO team provides cloud migration services for your businesses. Our team will help you move your data and software
    to the cloud. MCRO Tech knows how to handle your data management, therefore, your business will be in the right
    hands.
    <br />
    <br />
    When moving your business to the cloud, it is essential to use cloud migration services because they can save your
    business from high costs and headaches. Our DevOps team offers cloud migration services that bring a lot of
    advantages to your business, such as improved productivity, increased scalability, and simplified management.
    <br />
    <br />
    Some of the most common use cases for this service are moving from on-premise systems to the public cloud, moving
    from private cloud to public cloud or moving from hybrid clouds to a single platform.
  </div>
    }, {
      title: 'Cloud Integration',
      description:
  <div>
    Cloud integration is a crucial part of your business success. This service aims to connect the cloud service with
    your existing IT infrastructure. This enables you to benefit from cloud features and capabilities like data
    storage, processing, file sharing, and automation, without investing in costly hardware or software. The most
    frequent cloud integrations we use are Amazon Web Services (AWS), Google Cloud Platform (GCP),  Microsoft Azure
    and on premise custom hosting/infrastructure if required.
    <br />
    <br />
    MCRO has a devoted and specialised DevOps team prepared to contribute to your business development and increase
    your ROI. With cloud integration, you can quickly deploy new charges, scale up or down and reduce the total cost
    of ownership by reducing the number of servers.
  </div>
    }, {
      title: 'Hosting',
      description:
  <div>
    <a href="https://mcro.tech" style={{ color: '#0363c1' }}> MCRO </a>
    team provides the best cloud hosting service that assures web space, bandwidth and other services to
    individuals or companies. The essential service offered by a hosting company is providing an Internet Protocol
    (IP) address for a client's website.
    <br />
    <br />
    The DevOps from our team will set up the server infrastructure and take care of security issues such as issues or
    malware. Hosting is also a cost-effective choice. If you have many websites or ones that aren't updated regularly,
    hosting them will save you up to 80% on your monthly fees.
    <br />
    <br />
    Whenever it’s possible we recommend a serverless architecture for your application’s setup. In AWS such
    architecture will use S3 Bucket + CloudFront for Web Front-end and AWS Lambda Functions for your API. In this way
    you won’t have to worry about scaling, the cloud will do it automatically for you. Same kind of setup exists in
    other infrastructure platforms like Google Cloud or MS Azure.
  </div>
    }, {
      title: 'Big Data',
      description:
  <div>
    In today's commercial world, big data analytics is a must-have tool. It is a process of collecting and analysing
    data from multiple sources to extract insights used for decision-making. It aids in comprehending consumer
    behaviour and preferences, forecasting future behaviour, and enhancing customer experience.
    <br />
    <br />
    MCRO team offers Big Data services that help organisations in various ways. Businesses must adopt big data
    analytics technologies to stay ahead in the race for development to keep up with the fast pace of technology.
    <br />
    <br />
    Tools like ElasticSearch, NoSQL Databases (MongoDB, DynamoDB, Cassandra) or even SQL DBs setup right for scale help
    us handle big data systems in a performant and cost efficient way. Such tools combined with others like TensorFlow
    are a real benefit to training AI models.
  </div>
    },
  ]
};
