import React from 'react';
import { Link } from 'gatsby';

import arrowRight from '../assets/images/svg/angle-right.svg';

import Subtitle from './Subsection/Subtitle';

const CaseStudyBanner = (props) => {
  const {
    category, title, description, image, className, subcategory, linkToGo } = props;

  return (
    <div className={`case-study-banner utils__flex services-banner ${className}`}>
      <div className="case-study-banner--section">
        <div className="case-study-banner--section__category-wrapper">
          <Link
            className="case-study-banner--section__category utils__no-padding"
            to={linkToGo}
          >
            {category}
          </Link>
          <img src={arrowRight} alt="arrow right" className="case-study-banner--section__category-icon" />
          <div className="case-study-banner--section__category utils__no-padding" tabIndex="0">
            {subcategory}
          </div>
        </div>
        <Subtitle
          titleType="h1"
          withoutLine
          title={title}
        />
        <p
          className="case-study-banner--section__text utils__half-of-screen services-banner service-banner-description"
          tabIndex="0"
        >
          {description}
        </p>
      </div>

      <img src={image} alt="banner" className="servicesImageStyle" />
    </div>
  );
};

export default CaseStudyBanner;
