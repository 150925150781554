import React, { Component } from 'react';
import { Link } from 'gatsby';

import CheckCircleIcon from '../assets/images/services/services_icons/check-circle.svg';
import ArrowDownIcon from '../assets/images/services/services_icons/arrow-down.svg';

import Layout from './GatsbyLayout';
import Banner from './Banner';
import Sidebar from './Navigation/Sidebar';
import Subtitle from './Subsection/Subtitle';

import data from '../data/our-work-data';
import Helmet from './Helmet';

class ServicesPageDetails extends Component {
  renderServicesIncludedList = () => {
    const { service: { servicesIncluded } } = this.props;

    return (
      <div className="list">
        {
          servicesIncluded.map(included => (
            <div className="wrapper">
              <img src={CheckCircleIcon} alt="icon" className="icon" />
              {included}
            </div>
          ))
        }
      </div>
    );
  }

  renderCategory = (category) => {
    const { title, description } = category;

    return (
      <div key={title.toUpperCase()} id={title.toUpperCase()} className="scroll-page__category">
        <div className="category-wrapper">
          <h2 className="font-large font-weight-bold category-title" tabIndex="0">
            {title}
          </h2>
          <div className="description font-body1" tabIndex="0">
            {description}
          </div>
        </div>
      </div>
    );
  }

  renderProject = (project) => {
    const { bannerImage, shortTitle, linkId, shortDescription, id } = project;

    const path = `/our-work/${linkId}/`;

    return (
      <div className="article">
        <div className="article__image services-image">
          <Link to={path} tabIndex="-1">
            <img
              src={bannerImage}
              className="article__image project-image"
              alt={shortTitle}
              title={shortTitle}
            />
          </Link>
        </div>
        <div className="article__title no-margin-padding">
          <Link key={id} to={path}>{shortTitle}</Link>
        </div>
        <div
          className="article__info no-margin-padding article__additional-margin"
          tabIndex="0"
        >
          {shortDescription}
        </div>
      </div>
    );
  }

  renderFeaturedProjects = () => {
    const { service } = this.props;
    const { title } = service;

    const projects = data.filter(item => item.servicesTags.includes(title));

    const finalProjects = projects.map((project => this.renderProject(project))).slice(0, 3);

    return (
      <div>
        <Subtitle
          title="Featured Projects"
          withoutLine
          titleType="withoutDot"
        />

        <div className="articles main main__inner features-projects">
          {finalProjects}
        </div>
      </div>
    );
  }

  renderHelmet() {
    const { service } = this.props;
    const { title, keywords, shortDescription, image, id } = service;

    return (
      <Helmet
        title={`${title} | MCRO`}
        description={`${shortDescription}`}
        keywords={`${keywords}`}
        thumbnail={image}
        canonical
        canonicalPage={`services/${id}/`}
      />
    );
  }

  render() {
    const { location, service } = this.props;
    const { title, subtitle, shortDescription, image, offerDescription, categories } = service;

    return (
      <Layout location={location}>
        {this.renderHelmet()}

        <div className="main services main__inner">
          <Banner
            category="SERVICES"
            subcategory={title.toUpperCase()}
            title={title}
            description={shortDescription}
            linkToGo="/services/"
            image={image}
            additionalServiceStyle="services-banner"
            servicesImageStyle="servicesImageStyle"
            isForServices
          />
          <div className="services-details">
            <div className="offers-wrapper">
              <div className="explanations">
                <div className="description-wrapper">
                  <div className="title">
                    {subtitle}
                  </div>

                  <div className="description">
                    {offerDescription}
                  </div>
                  <Link
                    to={categories[0].title.toUpperCase()}
                    className="services-button button-primary-light"
                    spy
                    smooth
                    offset={-100}
                    duration={500}
                  >
                    Find out more
                    <img src={ArrowDownIcon} alt="arrow-icon" className="button-icon" />
                  </Link>
                </div>

                <div className="divider" />

                {this.renderServicesIncludedList()}

              </div>

              <div className="main-container">
                <Sidebar
                  all={categories.map(category => category.title)}
                />
                <div className="scroll-page categories">
                  {
                    categories.map(category => this.renderCategory(category))
                  }
                </div>
              </div>

              {this.renderFeaturedProjects()}

            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ServicesPageDetails;
